import './App.css';
import { useEffect, useState, useCallback } from 'react';

// REACT_APP_URL
// REACT_APP_PORT

let API_ENDPOINT = 'http://' + process.env.REACT_APP_APIURL;
if (process.env.REACT_APP_MODE !== "prod") {
	API_ENDPOINT += ':' + process.env.REACT_APP_APIPORT;
}

console.log("API_ENDPOINT", API_ENDPOINT);

const templateTypes = [
	{"value":"tipalti", "text":"Tipalti"}
];
/*
{"value":"chase-access", "text":"Chase Access"},
{"value":"payoneer", "text":"Payoneer"},
{"value":"paypal", "text":"PayPal"},
{"value":"ramp", "text":"Ramp"},
{"value":"rho", "text":"Rho"},
{"value":"spendesk", "text":"Spendesk"},
{"value":"stripe", "text":"Stripe"},
{"value":"tipalti", "text":"Tipalti"},
{"value":"volksbank", "text":"Volksbank"},
{"value":"wise", "text":"Wise"},
*/

function App() {

	const [filesWithError, setFilesWithError] = useState([]);
	const [filesToUpload, setFilesToUpload] = useState([]);
	const [templateType, setTemplateType] = useState(templateTypes[0].value);

	const validateFile = (filename) => {
		return /\.(csv|xlsx)/g.test(filename.toLowerCase());
	}

	const removeFileWithError = (errorIndex) => {
		const newData = filesWithError.filter((dataItem, dataIndex) => errorIndex !== dataIndex);
		setFilesWithError(newData);
	}

	const createFileToUpload = (newfile) => {
		setFilesToUpload((files) => [...files, {
			type: "upload", 
			name: newfile.name,
			file: newfile
		}]);
	}

	const removeFileToUpload = (uploadIndex)=> {
		setFilesToUpload((files) => files.filter((fileItem, fileIndex) => {
			return uploadIndex !== fileIndex;
		}));
	}

	const updateFileStatus = useCallback((uploadIndex, fileDownloadPath, filename, status)=> {
		let newFiles = [...filesToUpload];
		newFiles[uploadIndex] = {
			type: status, 
			file: fileDownloadPath,
			name: filename
		};
		setFilesToUpload(newFiles);
	}, [setFilesToUpload, filesToUpload])

	const prepareFiles = (files) => {
		files = Array.from(files);
		for (let i=0; i<files.length; i++) {
			const file = files[i];
			if (validateFile(file.name)) {
				createFileToUpload(file);
			} else {
				setFilesWithError((data) => [...data, file]);
			}
		}
	}

	const onDrop = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (event.dataTransfer) {
			prepareFiles(event.dataTransfer.files);
		}
	}

	const onDragOver = (event) => {
		event.preventDefault();
		event.stopPropagation();
	}

	const onFilesChange = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (event.target.files) {
			prepareFiles(event.target.files);
		}
	}

	const onTemplateChange = (event) => {
		setTemplateType(event.target.value);
	}

	const [isProcessing, setIsProcessing] = useState(false);
	const [uploadingFileIndex, setUploadingFileIndex] = useState(0);
	
	const processFiles = () => { setIsProcessing(true); }
	const clearAll = () => {
		setFilesWithError([]);
		setFilesToUpload([]);
		setIsProcessing(false);
		setUploadingFileIndex(0);
	}

	useEffect(() => {
		if (isProcessing) {
			if (uploadingFileIndex < filesToUpload.length) {

				const item = filesToUpload[uploadingFileIndex];
				
				// Before sending the file update its status to on porgress
				if (item.type === "upload") {
				
					updateFileStatus(uploadingFileIndex, item.file, item.name, "progress");

				// When the status is on progress we send the file to the api
				} else if (item.type === "progress") {

					const formData = new FormData();
					formData.append('file', item.file);
					formData.append('template', templateType);

					const xhr = new XMLHttpRequest();
					xhr.responseType = 'json';
					xhr.open('POST', API_ENDPOINT + '/parse');

					xhr.onload = () => {
						if (xhr.status === 200) {
							updateFileStatus(
								uploadingFileIndex, 
								API_ENDPOINT + "/converted/" + xhr.response.file,
								xhr.response.file,
								"download"
							);
							setUploadingFileIndex((index) => index+1);
							
						} else {
							
							updateFileStatus(uploadingFileIndex,  null, item.name, "error");
							setUploadingFileIndex((index) => index+1);
							
						}
					}

					xhr.send(formData);
					
				}

			} else {

				setIsProcessing(false);

			}
			
		}
	}, [isProcessing, uploadingFileIndex, filesToUpload, templateType, updateFileStatus]);

	return (
		<div id="payment-parsing-app">
			
			<div className="form">
				<h1>Payment Parsing Tool</h1>
				<p className='info'>Just drag and drop your <strong>xlsx</strong> or <strong>csv</strong> files you want to parse and they'll be converted.</p>

				<label htmlFor="templateType">Template Type</label>
				<select id="templateType" className="combo" onChange={onTemplateChange}>
					{templateTypes.map((type, index) => {
						return <option key={"template.type."+index} value={type.value}>{type.text}</option>
					})}
				</select>

				<div className="drag-area" onDrop={onDrop} onDragOver={onDragOver}>
					<input type='file' multiple={true} onChange={onFilesChange} />
					<span>Drag & Drop</span>
				</div>

				<div className="files">
					{filesToUpload.map((item, index) => {
						if (item.type === "upload") {
							
							return <p 
								key={"file-upload-"+index} 
								className="file"
							><span className="filename">{item.name}</span> <button onClick={(event) => {
								removeFileToUpload(index);
							}}>Delete</button></p>

						} else if (item.type === "download") {
							return <p 
								key={"file-download-"+index} 
								className="file download"
							><a href={item.file} target="_blank" rel="noreferrer" className="filename">{item.name}</a> <button onClick={(event) => {
								removeFileToUpload(index);
							}}>Delete</button></p>

						} else if (item.type === "progress") {

							return <p 
								key={"file-progress-"+index} 
								className="file progress"
							><span className="filename">{item.name}</span> <span className="loading"></span></p>

						} else if (item.type === "error") {

							return <p 
								key={"file-error-"+index} 
								className="file error"
							><span className="filename">{item.name}</span></p>

						}

					})}
				</div>
				
				<div className="errors">
					{filesWithError.map((file, index) => {
						return <p 
							key={"file-error-"-index} 
							className="error"
						>File {file.name} not supported <button onClick={(event) => {
							removeFileWithError(index);
						}}>Delete</button></p>
					})}
				</div>

				<div className="options">

					<div className="progress">
						<span className="done">{uploadingFileIndex}</span> / <span className="total">{filesToUpload.length}</span> files
					</div>
					
					<div>
						{(filesToUpload.length>0) && (
						<button 
							className="clear" 
							onClick={clearAll}
						>Clear All</button>
						)}

						<button 
							onClick={processFiles} 
							className={filesToUpload.length===0?"disabled":""} 
							disabled={filesToUpload.length===0}
						>Process Files</button>  
					</div>
					
				</div>

			</div>
			
		</div>
	);
}

export default App;
